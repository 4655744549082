/**
 * While normally the Services files exist all collectively in one place,
 * the CMS sub-project is intended to be broken out and moved to a separate web service
 * as we improve our dev-ops approach.
 *
 * As such, all of its content should be contained here in the cms subfolder of the components namespace.
 *
 * Do not refactor to move this to the same space as other component structure.
 *
 */

import I18n from '../../../helpers/I18n'

export enum TechnicalDocumentType {
  none = 0,
  datasheet = 1,
  how_to_guide = 2,
  user_manual = 4,
  design_guide = 12,
  maintenance_guide = 14,
  application_guide = 16,
}

export interface TechnicalDocumentFields {
  readonly id: number
  readonly title: string
  readonly sub_heading?: string
  readonly title_image: string
  readonly title_image_visibility: boolean
  readonly description: string
  readonly content_html: string
  readonly content_json?: string
  readonly url_slug: string
  readonly url_type: string
  readonly editor: string
  readonly document_type: number
  readonly proficiency_level: number
  readonly version_text?: string
  readonly technology_list: ReadonlyArray<string>
  readonly created_at: string
  readonly updated_at: string
  readonly document_url?: string
  readonly sub_category_text?: string
  readonly main_category_text?: string
  readonly version_name?: string
}

export interface HowToGuide extends TechnicalDocumentFields {
  readonly document_type: TechnicalDocumentType.how_to_guide
}

export interface Datasheet extends TechnicalDocumentFields {
  readonly document_type: TechnicalDocumentType.datasheet
}

export interface UserManual extends TechnicalDocumentFields {
  readonly document_type: TechnicalDocumentType.user_manual
}

export interface DesignGuide extends TechnicalDocumentFields {
  readonly document_type: TechnicalDocumentType.design_guide
}

export interface MaintenanceGuide extends TechnicalDocumentFields {
  readonly document_type: TechnicalDocumentType.maintenance_guide
}

export interface ApplicationGuide extends TechnicalDocumentFields {
  readonly document_type: TechnicalDocumentType.application_guide
}

export interface GenericTechnicalDocument extends TechnicalDocumentFields {
  readonly document_type: TechnicalDocumentType.none
}

export type TechnicalDocument =
  | HowToGuide
  | Datasheet
  | UserManual
  | DesignGuide
  | MaintenanceGuide
  | ApplicationGuide
  | GenericTechnicalDocument

export interface LegacyCaseStudy {
  title?: string
  title_image?: string
  url_slug?: string
  company?: string
  content_json: string
}

export interface CaseStudy {
  title: string
  title_image: string
  url_slug: string
  company: string
  description: string
  read_time_minutes_text: string
  logo_image_url: string
  quote_text: string
  quote_author: string
  youtube_id: string
  content_json: {}
  case_study_stats: CustomerStoryStat[]
  full_path?: string
  subtitle?: string
}

export interface CustomerStoryStat {
  description: string
  icon: string
}

export interface CaseStudyContent {
  readonly header?: CaseStudyHeader
  readonly info?: {
    readonly logo?: string
    readonly complexity_level?: string
    readonly cost?: string
    readonly cost_currency?: string
    readonly cost_label?: string
    readonly design_id?: string
  }
  readonly summary?: CaseStudySummary
  readonly stats?: CaseStudyStats
  readonly project_overview?: {
    readonly title?: string
    readonly intro?: string
    readonly image_url?: string
    readonly content?: [
      {
        readonly paragraph?: string
      }
    ]
    readonly char_count?: number
  }
  readonly project_specs?: [
    {
      readonly label?: string
      readonly label_extra?: string
      readonly description?: string
    }
  ]
  readonly gallery?: ReadonlyArray<{
    readonly image_url?: string
    readonly thumbnail_image_url?: string
  }>

  readonly quote?: {
    readonly text?: string
    readonly author?: string
    readonly logo?: string
  }
}

export interface CaseStudyHeader {
  quote: string
  image_url: string
  title: string
  subtitle: string
}

export interface CaseStudySummary {
  readonly headerTitle?: string
  readonly company?: string
  readonly challenge?: string
  readonly solution?: string
  readonly youtube_id?: string
  readonly image_url?: string
}

export interface CaseStudyStats {
  readonly completed_in?: string | CaseStudyStat
  readonly design_time?: string | CaseStudyStat
  readonly assembly_time?: string | CaseStudyStat
  readonly commissioning?: string | CaseStudyStat
}

export interface CaseStudyStat {
  readonly amount?: string
  readonly label?: string
  readonly name?: string
}
export interface Faq {
  readonly id: number
  readonly question: string
  readonly answer: string
  readonly technology_list?: ReadonlyArray<string>
  readonly part_ids?: ReadonlyArray<string>
  readonly part_family_ids?: ReadonlyArray<string>
  readonly created_at?: string
  readonly updated_at?: string
}

export interface VideoTutorial {
  readonly id: number
  readonly title: string
  readonly link: string
  readonly content: string
  readonly span: number
  readonly youtube_id: string
  readonly created_at: string
  readonly updated_at: string
  readonly technology_list: ReadonlyArray<string>
  readonly main_category_text: string
  readonly sub_category_text: string
}

export interface Webinar {
  readonly id: number
  readonly title: string
  readonly title_image: { url: string; thumb: string }
  readonly description: string
  readonly host: string
  readonly technology_list: ReadonlyArray<string>
  readonly part_ids: ReadonlyArray<string>
  readonly part_family_ids: ReadonlyArray<string>
  readonly youtube_id: string
  readonly span: number
  readonly created_at: string
  readonly updated_at: string
  readonly uuid: string
  readonly url_slug: string
}

export interface AggregateDocsQuerySettings {
  readonly search?: string
  readonly scope?: ReadonlyArray<string> | string
  readonly limit?: number
  readonly tech_docs_scope?: ReadonlyArray<string> | string
  readonly applications?: ReadonlyArray<string>
  readonly technologies?: ReadonlyArray<string>
  readonly order_by?: string
  readonly order?: 'ASC' | 'DESC'
  readonly without_content?: boolean
  readonly feature?: 'featured'
}
export interface AggregateDocsResponse {
  readonly tutorial_videos: ReadonlyArray<VideoTutorial>
  readonly case_studies: ReadonlyArray<CaseStudy>
  readonly tech_docs: ReadonlyArray<TechnicalDocument>
  readonly webinars: ReadonlyArray<Webinar>
  readonly faqs: ReadonlyArray<Faq>
}

export type DocScope = 'faqs' | 'case_studies' | 'tech_docs' | 'webinars' | 'tutorial_videos'
export interface LandingPageSectionTypes {
  caseStudy: React.ComponentType<LPCaseStudySection>
  designCarousel: React.ComponentType<LPDesignCarouselSection>
  hero: React.ComponentType<LPHeroSection>
  applicationSummary: React.ComponentType<ApplicationSummarySection>
  applicationStats: React.ComponentType<ApplicationStatsSection>
  infoGraphic: React.ComponentType<LPInfoGraphicSection>
  lrContent: React.ComponentType<LPLRContentSection>
  multiTile: React.ComponentType<LPMultiTileSection>
  showcase: React.ComponentType<LPShowcaseSection>
  parts: React.ComponentType<LPPartsSection>
  promoVideo: React.ComponentType<LPPromoVideoSection>
  resource: React.ComponentType<LPResourceSection>
  standardGeneral: React.ComponentType<LPStandardGeneralSection>
  standardPartnerLogos: React.ComponentType<LPStandardGeneralSection>
  standardQuotes: React.ComponentType<LPStandardQuotesSection>
  standardRequestInfo: React.ComponentType<LPStandardRequestInfoSection>
  standardStartBuilding: React.ComponentType<LPStandardStartBuildingSection>
  table: React.ComponentType<LPTableSection>
  vention101: React.ComponentType<LPVention101Section>
  pureHtml: React.ComponentType<LPPureHtmlSection>
  machineLogicPlans: React.ComponentType<LPMachineLogicPlansSection>
  machineLogicFeatures: React.ComponentType<LPMachineLogicFeaturesSection>
}

export type LandingPageSection =
  | LPCaseStudySection
  | LPDesignCarouselSection
  | LPHeroSection
  | LPInfoGraphicSection
  | LPLRContentSection
  | LPMultiTileSection
  | LPShowcaseSection
  | LPPartsSection
  | LPPromoVideoSection
  | LPResourceSection
  | LPStandardGeneralSection
  | LPStandardPartnerLogosSection
  | LPStandardQuotesSection
  | LPStandardRequestInfoSection
  | LPStandardStartBuildingSection
  | LPTableSection
  | LPVention101Section
  | LPPureHtmlSection
  | LPMachineLogicPlansSection
  | LPMachineLogicFeaturesSection

export type TextAlign = 'left' | 'right' | 'center'
export type CallToActionColor = 'primary' | 'secondary' | 'outline' | 'outline-white' | 'white'
export type BackgroundColor =
  | 'white'
  | 'vention-blue'
  | 'light-grey'
  | 'dark-grey'
  | 'accent-blue'
  | 'vention-grey'
export type TextColor = 'dark' | 'light'

export const CTA_BUTTON_CLASS_NAMES = {
  primary: 'button-success',
  secondary: 'button-vention',
  outline: 'button-outline',
  'outline-white': 'button-white-outline',
  white: 'button-white',
}

type CallToActionTypes = 'talk-to-expert'

export const CallToActionDefinitions: Record<CallToActionTypes, CallToActionProperties> = {
  'talk-to-expert': {
    label: I18n.t('views.cms.landing_pages.talk_to_expert'),
    urlLocaleKey: 'talk_to_expert',
    color: 'secondary',
    leftIcon: 'icon-request-customization',
  },
}

export const CallToActionDefinition = (cta: CallToActionPredefined): CallToActionProperties => {
  const newCta = { ...CallToActionDefinitions[cta.predefinedType] }

  if (cta.color) {
    newCta.color = cta.color
  }

  return newCta
}

interface CallToActionPredefined {
  readonly predefinedType: CallToActionTypes
  readonly color?: CallToActionColor
}

export type CallToAction = CallToActionProperties | CallToActionPredefined
interface CallToActionProperties {
  readonly label?: string
  readonly url?: string
  readonly urlLocaleKey?: string
  readonly color?: CallToActionColor
  readonly leftIcon?: string
}

export interface SectionStyle {
  readonly paddingTop?: string
  readonly paddingBottom?: string
  readonly textAlign?: TextAlign
  readonly backgroundColor?: BackgroundColor
  readonly backgroundImage?: string
  readonly textColor?: TextColor
}

export type LRTextAlign = 'left' | 'right'
export type MediaType = 'image' | 'video'
export type TitleSize = 'h2' | 'h3'

export interface LRContentObject {
  readonly subtitle?: string
  readonly content?: string
}

export interface Vention101Object {
  readonly title?: string
  readonly subtitle?: string
}

type ResourceDocModel = 'CaseStudy' | 'TechnicalDocument' | 'Faq' | 'Webinar' | 'TutorialVideo'

type ResourceTechDocType =
  | 'how_to_guide'
  | 'user_manual'
  | 'datasheet'
  | 'design_guide'
  | 'maintenance_guide'
  | 'application_guide'

export interface ResourceDocObject {
  readonly title?: string
  readonly url_slug?: string
  readonly youtube_id?: string
  readonly class_model_name?: ResourceDocModel
  readonly document_type_name?: ResourceTechDocType
  readonly title_image?: string
  readonly thumbnail_title_image?: string
}

export type HeroImageLayout = 'half' | 'full'
export interface HeroCaseStudy {
  readonly uuid: string
}

export interface MultiTile {
  readonly title?: string
  readonly titlePrefix?: string
  readonly imageUrl?: string
  readonly imageAlt?: string
  readonly description?: string
  readonly linkUrl?: string
  readonly linkLabel?: string
  readonly icon?: React.ReactNode
}

export interface BaseSection {
  readonly style?: SectionStyle
}

export interface InvalidSection {
  readonly type: undefined | null
}

export interface StandardStartBuildingSectionButton {
  readonly label: string
  readonly url?: string
}

export interface CreateScopeOfWorkButton {
  readonly label: string
}

// TODO: type each section below according to the JSON response

export interface LPCaseStudySection extends BaseSection {
  readonly type: 'caseStudy'
  readonly title: string
  readonly uuid: string
  readonly resourcesByUuid: Record<string, CaseStudy | LegacyCaseStudy>
}

export interface LPDesignCarouselSection extends BaseSection {
  readonly type: 'designCarousel'
  readonly title?: string
  readonly subtitle?: string
  readonly designs?: ReadonlyArray<number>
  readonly cta?: CallToAction
}
export interface LPHeroSection extends BaseSection {
  readonly type: 'hero'
  readonly pageTypeFlag?: string
  readonly textColor?: TextColor
  readonly title?: string
  readonly subtitle?: string
  readonly caseStudy?: HeroCaseStudy
  readonly withEmailInputCTA?: boolean
  readonly partnerLogo?: string
  readonly partnerLogoWidth?: number
  readonly imageUrl?: string
  readonly imageLayout?: HeroImageLayout
  readonly sectionCTA?: CallToAction
  readonly sectionSecondaryCTA?: CallToAction
  readonly scopeOfWorkCTA?: CreateScopeOfWorkButton
  readonly resourcesByUuid: Record<string, ResourceDocObject>
  readonly mode?: 'light' | 'dark'
}

export interface LPMachineLogicPlansSection extends BaseSection {
  readonly type: 'machineLogicPlans'
}

export interface LPMachineLogicFeaturesSection extends BaseSection {
  readonly type: 'machineLogicFeatures'
}

export interface LPInfoGraphicSection extends BaseSection {
  readonly type: 'infoGraphic'
  readonly title?: string
  readonly subtitle?: string
  readonly textAlign?: TextAlign
  readonly imageUrl?: string
  readonly imageUrlMobile?: string
  readonly description?: string
  readonly cta?: CallToAction
  readonly alt?: string
  readonly imageMaxWidth?: number
}
export interface LPLRContentSection extends BaseSection {
  readonly type: 'lrContent'
  readonly pageTypeFlag?: string
  readonly title?: string
  readonly videoUrl?: string
  readonly videoModalUrl?: string
  readonly videoRatio?: number
  readonly imageUrl?: string
  readonly titleSize?: TitleSize
  readonly textAlign?: LRTextAlign
  readonly content?: ReadonlyArray<LRContentObject>
  readonly cta?: CallToAction
  readonly alt?: string
  readonly imageBackgroundColor?: BackgroundColor
}

export interface LPMultiTileSection extends BaseSection {
  readonly type: 'multiTile'
  readonly supertitle?: string
  readonly title?: string
  readonly subtitle?: string
  readonly textAlign?: TextAlign
  readonly titleTextAlign?: TextAlign
  readonly countTiles?: number
  readonly tiles?: ReadonlyArray<MultiTile>
  readonly ctaBtn?: {label:string}
  readonly cta?: CallToAction
  readonly imageMaxWidth?: number
  readonly imageBackgroundColor?: BackgroundColor
}

export interface LPShowcaseSection extends BaseSection {
  readonly type: 'multiTile'
  readonly title?: string
  readonly backgroundImg?: string
  readonly subtitle?: string
  readonly textAlign?: TextAlign
  readonly titleTextAlign?: TextAlign
  readonly countTiles?: number
  readonly tiles?: ReadonlyArray<MultiTile>
  readonly cta?: CallToAction
  readonly imageMaxWidth?: number
  readonly imageBackgroundColor?: BackgroundColor
}

export interface LPPartsSection extends BaseSection {
  readonly type: 'parts'
}
export interface LPPromoVideoSection extends BaseSection {
  readonly type: 'promoVideo'
  readonly title?: string
  readonly subtitle?: string
  readonly youTubeId?: string
  readonly cta?: CallToAction
}
export interface LPResourceSection extends BaseSection {
  readonly type: 'resource'
  readonly title?: string
  readonly subtitle?: string
  readonly uuids?: ReadonlyArray<string>
  readonly sectionCTA?: CallToAction
  readonly resourcesByUuid: Record<string, ResourceDocObject>
}
export interface LPStandardGeneralSection extends BaseSection {
  readonly type: 'standardGeneral'
}

export interface LPStandardPartnerLogosSection extends BaseSection {
  readonly type: 'standardPartnerLogo'
}

export interface LPStandardQuotesSection extends BaseSection {
  readonly type: 'standardQuotes'
}

export interface LPStandardRequestInfoSection extends BaseSection {
  readonly type: 'standardRequestInfo'
}

export interface LPStandardStartBuildingSection extends BaseSection {
  readonly type: 'standardStartBuilding'
  readonly title?: string
  readonly description?: string
  readonly buttons?: {
    getStarted: StandardStartBuildingSectionButton
    contactUs: StandardStartBuildingSectionButton
  }
}

export interface LPTableSection extends BaseSection {
  readonly defaultClassName?: string
  readonly type: 'table'
  readonly data?: ReadonlyArray<{ [key: string]: string }>
  readonly tableStyle: {
    readonly columnWidths?: ReadonlyArray<number>
    readonly layout?: 'vertical' | 'horizontal'
    readonly headerBackgroundColor?: 'vention-blue' | 'white'
    readonly responsiveStyle?: 'cards' | 'scroll'
    readonly borderStyle?: 'solid' | 'dashed'
    readonly innerCellTextAlignment?: 'left' | 'center' | 'right'
    readonly alterningRowBackgroundColor?: boolean
    readonly borderRow?: boolean
    readonly borderColumn?: boolean
  }
  readonly tableHeader?: string
  readonly subtitle?: string
  readonly description?: string
  readonly tableFooter?: string
  readonly cta?: CallToAction
  readonly titleTextAlign?: TextAlign
}
export interface LPVention101Section extends BaseSection {
  readonly type: 'vention101'
  readonly title?: string
  readonly items?: ReadonlyArray<Vention101Object>
  readonly display?: 'mobile'
}

export interface LPPureHtmlSection extends BaseSection {
  readonly type: 'pureHtml'
  readonly html?: string
  readonly css?: string
  readonly cta?: CallToAction
}

export interface TechDocSectionTypes {
  image: React.ComponentType<TechDocImage>
  lr: React.ComponentType<TechDocLR>
  accordion: React.ComponentType<TechDocAccordion>
  textBlock: React.ComponentType<TechDocTextBlock>
  table: React.ComponentType<TechDocTable>
  video: React.ComponentType<TechDocVideo>
  partDocuments: React.ComponentType<TechDocPartDocuments>
  downloads: React.ComponentType<TechDocDownloads>
}

export type TechDocSection =
  | TechDocImage
  | TechDocLR
  | TechDocAccordion
  | TechDocTextBlock
  | TechDocTable
  | TechDocVideo
  | TechDocPartDocuments
  | TechDocDownloads

export interface TechDocAccordionSectionHtml {
  readonly type: 'html'
  readonly value: string
}
export interface TechDocAccordionSectionSnippet {
  readonly type: 'snippet'
  readonly title: string
  readonly value: string
  readonly language: 'python' | 'javascript'
  readonly cta?: {
    label: string
    href: string
  }
}

export interface TechDocAccordion extends BaseSection {
  readonly type: 'accordion'
  readonly title: string
  readonly footNote?: string
  readonly data: ReadonlyArray<TechDocAccordionSectionHtml | TechDocAccordionSectionSnippet>
  readonly cta?: CallToAction
}

export interface TechDocImage extends BaseSection {
  readonly type: 'image'
  readonly urls?: ReadonlyArray<{
    default: string
    mobile?: string
    caption?: string
    alt: string
  }>
  readonly cta?: CallToAction
}

export interface TechDocVideo extends BaseSection {
  readonly type: 'video'
  readonly youTubeId?: string
}

export interface TechDocLR extends BaseSection {
  readonly type: 'lr'
  readonly picture?: {
    url: string
    alt: string
  }
  readonly text?: string
  readonly imageAlignment?: 'left' | 'right'
  readonly cta?: CallToAction
}

export interface TechDocTextBlockContent {
  readonly title?: string
  readonly content?: ReadonlyArray<string>
  readonly subSections?: ReadonlyArray<TechDocTextBlockContent>
  readonly component?: TechDocSection
}

export interface TechDocTextBlock extends BaseSection, TechDocTextBlockContent {
  readonly type: 'textBlock'
  readonly cta?: CallToAction
}

export interface TechDocAccordion extends BaseSection {
  readonly type: 'accordion'
}

export type TechDocTable = LPTableSection

export interface TechDocContentsObject {
  readonly heading: string
  readonly anchor: string
  readonly subHeadings?: ReadonlyArray<TechDocContentsObject | null> | null
}

export interface TechDocContents extends BaseSection {
  readonly type: 'contents'
  readonly contents: ReadonlyArray<TechDocContentsObject | null>
  readonly titleImage: string
  readonly titleImageVisible: boolean
}

export interface TechDocDownloads extends BaseSection {
  readonly type: 'downloads'
  readonly title?: string
  readonly items?: ReadonlyArray<TechDocDownload>
}

export interface TechDocDownload {
  readonly label: string
  readonly url: string
}

export interface TechDocPartDocuments extends BaseSection {
  readonly type: 'partDocuments'
  readonly parts: ReadonlyArray<TechDocPartDownloads>
}

export interface TechDocPartDownloads {
  readonly id: number
  readonly name: string
  readonly part_number: string
  readonly image_path: string
  readonly part_path: string
  readonly download_2d_drawing?: string
  readonly download_3d_model?: string
}

export interface ApplicationSummarySection extends BaseSection {
  readonly title: string
  readonly summary: string
  readonly cta?: CallToAction
}

export interface ApplicationStatsSection extends BaseSection {
  readonly stats: { label: string; value: string }[]
}

export interface LandingPageEducationalResourcesSection extends BaseSection {
  readonly subtitle: string
  readonly title: string
  readonly description: string
  readonly cardDetails: [{
    icon: string
    title: string
    description: string
    category:string
    link_url: string
    link_text: string
  }]
}

export interface CtaDivider extends BaseSection{
  readonly description: string
  readonly btnText: string
  readonly btnUrl: string
}