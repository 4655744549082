import { gql } from "graphql-request";
import { ErrorMetadata, ID } from "../../Types";

export const CREATE_DESIGN_WITHIN_PROJECT_MUTATION = gql`
  mutation CreateDesignWithinProjectMutation(
    $projectId: ID!
    $name: String!
    $applicationCategoryCodes: String!
    $description: String!
  ) {
    project(id: $projectId) {
      item {
        designCreate(
          input: {
            name: $name
            designCategoryCodes: $applicationCategoryCodes
            description: $description
          }
        ) {
          design {
            id
            name
          }
          errors {
            title
            message
          }
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export type CreateDesignWithinProjectMutationDTO = {
  project: {
    item: {
      designCreate: {
        design: {
          id: string;
          name: string;
        };
      } & ErrorMetadata;
    };
  } & ErrorMetadata;
};

export type CreateDesignWithinProjectMutationParams = {
  projectId: ID;
  name: string;
  applicationCategoryCodes: string;
  description: string;
};

export const SAVE_PROJECT_DESIGN_MUTATION = gql`
  mutation SaveProjectDesign($projectId: ID!, $designId: ID!, $quantity: Int!) {
    projectItemSave(
      input: {
        projectId: $projectId
        itemType: Design
        itemId: $designId
        quantity: $quantity
      }
    ) {
      projectItem {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type SaveProjectDesignMutationDTO = {
  projectItemSave: {
    projectItem: {
      id: string;
    };
  } & ErrorMetadata;
};

export type SaveProjectDesignMutationParams = {
  projectId: ID;
  designId: ID;
  quantity: number;
};

export const DELETE_PROJECT_ITEM_MUTATION = gql`
  mutation DeleteProjectItem($id: ID!) {
    projectItemDelete(input: { id: $id }) {
      projectItem {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type DeleteProjectItemMutationDTO = {
  projectItemDelete: {
    projectItem: {
      id: string;
    };
  } & ErrorMetadata;
};

export type DeleteProjectItemMutationParams = {
  id: ID;
};
