import { QuestionType } from "../../components/erb_wrappers/dashboard/projects/types/Types";
import { TemplateQuestionDTO } from "./dtos";

const transformChceckboxAnswer = (
  values: Record<
    string,
    string | number | string[] | undefined | null | boolean
  >,
  allQuestions: TemplateQuestionDTO[],
): Record<string, string | number | string[] | undefined | null | boolean> => {
  const updatedValues = { ...values };
  allQuestions
    .filter((question: TemplateQuestionDTO) => {
      return (
        question.applicationCategoryTemplateQuestionType.questionType ===
        "checkbox"
      );
    })
    ?.forEach((question) => {
      const questionValue = updatedValues[question.id];
      if (questionValue === "yes" || questionValue === true) {
        updatedValues[question.id] = true;
      } else if (questionValue === "no" || questionValue === false) {
        updatedValues[question.id] = false;
      } else {
        updatedValues[question.id] = undefined;
      }
    });
  return updatedValues;
};

const transformNumberAnswer = (
  values: Record<
    string,
    string | number | string[] | undefined | null | boolean
  >,
  allQuestions: TemplateQuestionDTO[],
): Record<string, string | number | string[] | undefined | null | boolean> => {
  const updatedValues = { ...values };
  allQuestions
    .filter((question: TemplateQuestionDTO) => {
      const questionValue = values[question.id];
      return (
        questionValue !== null &&
        questionValue !== undefined &&
        questionValue !== "" &&
        (["integer", "decimal"] as QuestionType[]).includes(
          question.applicationCategoryTemplateQuestionType.questionType,
        )
      );
    })
    ?.forEach((question) => {
      const value = `${values[question.id]}`;
      if (!Number.isNaN(parseInt(value)) && !value.includes(".")) {
        updatedValues[question.id] = parseInt(value);
      } else if (!Number.isNaN(parseFloat(value))) {
        updatedValues[question.id] = parseFloat(value);
      }
    });
  return updatedValues;
};

export { transformChceckboxAnswer, transformNumberAnswer };
