import React from "react";
import { Facet as FacetController, FacetValue } from "@coveo/headless";
import { useEffect, useState } from "react";
import { TreeViewElement } from "@/components/ui/tree-view-api";
import { forOwnRight } from "lodash";
import { TreeMenuView } from "./TreeMenuView";
import { useControllerState } from "../../utils/useControllerState";
import { PartCategoryByCode } from "../../../pages/views/part_searches/Types";

export interface CategoryFacetProps {
  controller: FacetController;
  labels: PartCategoryByCode;
  title: string;
}

interface TreeViewElementWithFacet extends TreeViewElement {
  facet: FacetValue;
  isSelected?: boolean;
  children: TreeViewElementWithFacet[];
}

const lastCategoryCodeInHeigharchy = (category: string) =>
  category.split("|").pop();

const facetValueIsParentCategory = (facetValue: FacetValue) => {
  return !facetValue.value.includes("|")
}

export const latestPartCategoryByCode = (
  category: string,
  labels: PartCategoryByCode,
) => {
  const latestCategoryCode = lastCategoryCodeInHeigharchy(category);

  if (latestCategoryCode && labels[latestCategoryCode]) {
    return labels[latestCategoryCode];
  }

  // If the category is not found, return a default object
  return {
    name: latestCategoryCode || "",
    description: "",
  };
};

const groupByParentCategory = (
  facetValues: FacetValue[],
): Record<string, FacetValue[]> => {
  const groupedCategories: Record<string, FacetValue[]> = {};

  facetValues.forEach((facetValue) => {
    const parentCategory = extractParentCategory(facetValue);

    if (groupedCategories[parentCategory] === undefined) {
      groupedCategories[parentCategory] = [facetValue];
    } else {
      groupedCategories[parentCategory].push(facetValue);
    }
  });

  return groupedCategories;
};

const toTreeViewElements = (
  facetValuesGroupedByParent: Record<string, FacetValue[]>,
  labels: PartCategoryByCode,
  isValueSelected: (facetValue: FacetValue) => boolean,
  noCategoriesSelected,
): (TreeViewElementWithFacet)[] => {
  const tree: TreeViewElementWithFacet[] = [];

  forOwnRight(facetValuesGroupedByParent, (facetValues: FacetValue[], parentCategory: string) => {
    const children = facetValues
      .slice(1)
      .map<TreeViewElementWithFacet>((facetValue: FacetValue) => {
        const category = latestPartCategoryByCode(facetValue.value, labels);

        return {
          id: facetValue.value,
          name: `${category.name} (${facetValue.numberOfResults})`,
          isSelectable: true,
          facet: facetValue,
          isSelected: isValueSelected(facetValue),
          children: []
        };
      });

    const groupElement: TreeViewElementWithFacet = {
      id: parentCategory,
      name: `${latestPartCategoryByCode(parentCategory, labels).name} (${facetValues[0].numberOfResults})`,
      isSelectable: true,
      isSelected: isValueSelected(facetValues[0]),
      facet: facetValues[0],
      children: children
    };

    tree.push(groupElement);
  });

  tree.sort((a, b) => {
    if (a.isSelected || a.children?.some(child => isValueSelected(child.facet))) {
      return -2;
    } else if (b.isSelected || b.children?.some(child => isValueSelected(child.facet))) {
      return 2;
    }

    const comparison = a.name.localeCompare(b.name);

    if (comparison > 0) {
      return 1;
    } else if (comparison < 0) {
      return -1;
    } else {
      return 0;
    }
  });

  tree.unshift({
    id: "all-root",
    name: "All categories",
    isSelectable: true,
    isSelected: noCategoriesSelected,
    facet: undefined as any,
    children: []
  });

  return tree;
};

const extractParentCategory = (facetValue: FacetValue) => {
  return facetValue.value.split("|")[0];
};

const extractSubCategory = (facetValue: FacetValue) => {
  return facetValue.value.split("-")[1];
};

export const CategoryFacet: React.FC<CategoryFacetProps> = (props) => {
  const { controller, title } = props;
  const [facetsInitializedFromUrlPath, setFacetsInitializedFromUrlPath] =
    useState<boolean>(false);

  const state = useControllerState(controller);

  const noCategoriesSelected = !state.values.some((facet) => controller.isValueSelected(facet));

  const selectedFacetValue = state.values.find(
    (value) => value.state === "selected",
  );

  const syncUrlAnchorWithSelectedFacets = () => {
    const url = new URL(window.location.href);

    // selectedFacetValue: "IP|IP-NP"
    const parentCategory =
      selectedFacetValue && extractParentCategory(selectedFacetValue);
    const subCategory =
      selectedFacetValue && extractSubCategory(selectedFacetValue);

    if (parentCategory) {
      url.searchParams.set("category", parentCategory);
    } else {
      url.searchParams.delete("category");
    }

    if (subCategory) {
      url.searchParams.set("sub_category", subCategory);
    } else {
      url.searchParams.delete("sub_category");
    }

    window.history.replaceState({}, "", url);
  };

  const initializeFacetsFromUrlPath = () => {
    const url = new URL(window.location.href);

    const parentCategoryParams = url.searchParams.get("category");
    const subCategoryParam = url.searchParams.get("sub_category");

    if (parentCategoryParams && subCategoryParam) {
      const matchingFacetOption = state.values.find((facetValue) => {
        return (
          extractParentCategory(facetValue) === parentCategoryParams &&
          extractSubCategory(facetValue) === subCategoryParam
        );
      });

      if (matchingFacetOption && matchingFacetOption?.state !== "selected") {
        controller.toggleSingleSelect(matchingFacetOption);
      }
    } else if (parentCategoryParams) {
      const matchingFacetOption = state.values.find((facetValue) => {
        return extractParentCategory(facetValue) === parentCategoryParams;
      });

      if (matchingFacetOption && matchingFacetOption?.state !== "selected") {
        controller.toggleSingleSelect(matchingFacetOption);
      }
    }
  };

  useEffect(() => {
    if (!state.values.length) return;

    if (facetsInitializedFromUrlPath) {
      syncUrlAnchorWithSelectedFacets();
    } else {
      initializeFacetsFromUrlPath();

      setFacetsInitializedFromUrlPath(true);
    }
  }, [state.values.length, selectedFacetValue, facetsInitializedFromUrlPath]);

  if (!state.values.length) {
    return null;
  }

  const facetValuesGroupedByParent = groupByParentCategory(state.values);

  const treeViewElements = toTreeViewElements(
    facetValuesGroupedByParent,
    props.labels,
    controller.isValueSelected,
    noCategoriesSelected,
  );

  const handleOnValueSelected = (value: TreeViewElementWithFacet) => {
    if (value.facet && !controller.isValueSelected(value?.facet)) {
      controller.toggleSingleSelect(value?.facet);
    } else {
      controller.deselectAll();
    }
  };

  const initialExpendedItems = (): string[] => {
    if (!selectedFacetValue) { return []; }

    let parentCategory: string;

    if (facetValueIsParentCategory(selectedFacetValue)) {
      parentCategory = selectedFacetValue.value;
    } else {
      parentCategory = extractParentCategory(selectedFacetValue)
    }

    return treeViewElements
      .filter(element => element.facet && element.facet.value === parentCategory)
      .map(element => element.id);
  }

  return (
    <div className="min-w-56">
      <span className="text-start text-md-medium text-primary flex flex-row bg-white justify-between w-full py-2 px-1 border-0 border-b border-border-secondary border-solid mt-2 mb-4">
        {title}
      </span>
      <TreeMenuView
        initialExpendedItems={initialExpendedItems()}
        elements={treeViewElements}
        onClickElement={(element: any) => {
          handleOnValueSelected(element);
        }}
      />
    </div>
  );
};
