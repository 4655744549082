import React, { useEffect } from "react";
import {
  buildFacet,
  buildFieldSortCriterion,
  buildNumericFacet,
  buildNumericFilter,
  buildPager,
  buildQuerySummary,
  buildRelevanceSortCriterion,
  buildResultList,
  buildResultsPerPage,
  buildSearchBox,
  buildSort,
  Facet,
  loadQueryActions,
  NumericFacet,
  NumericFilter,
  Pager,
  QuerySummary,
  ResultList,
  SearchBox,
  SearchEngine,
  Sort,
  SortCriterion,
  SortOrder,
} from "@coveo/headless";
import { createContext, useContext } from "react";
import { createSearchEngine } from "../../utils/createEngine";
import { ecommerceFields } from "../../utils/ecommerceFields";
import { initializeUrlManager } from "../../utils/initializeUrlManager";
import { RegionContext } from "./RegionProvider";
import { ContinentContext } from "./ContinentProvider";

export enum EngineType {
  Search = "searchEngine",
  FeaturedParts = "featuredPartsEngine",
  NewParts = "newPartsEngine",
}

interface EngineContextType {
  [EngineType.Search]: SearchEngine;
  [EngineType.FeaturedParts]: SearchEngine;
  [EngineType.NewParts]: SearchEngine;
}

export const EngineContext = createContext<EngineContextType | null>(null);

export const useEngineContext = <T extends EngineType>(
  engine: T,
): EngineContextType[T] => {
  const context = useContext(EngineContext);

  if (!context) {
    throw new Error(`useEngineContext must be used within a EngineProvider`);
  }

  return context[engine];
};

export enum ControllerType {
  SearchBox = "searchBoxController",
  Sort = "sortController",
  Criteria = "criteria",
  CategoryFacet = "categoryFacetController",
  PriceFilter = "priceFilterController",
  Pager = "pagerController",
  ResultList = "resultListController",
  FeaturedPartsResultList = "featuredPartsResultListController",
  NewPartsResultList = "newPartsResultListController",
  QuerySummary = "querySummaryController",
  BrandFacet = "brandFacetController",
  PriceFacet = "priceFacetController",
}

interface ControllerContextType {
  [ControllerType.SearchBox]: SearchBox;
  [ControllerType.Sort]: Sort;
  [ControllerType.Criteria]: [string, SortCriterion][];
  [ControllerType.CategoryFacet]: Facet;
  [ControllerType.PriceFilter]: NumericFilter;
  [ControllerType.Pager]: Pager;
  [ControllerType.ResultList]: ResultList;
  [ControllerType.FeaturedPartsResultList]: ResultList;
  [ControllerType.NewPartsResultList]: ResultList;
  [ControllerType.QuerySummary]: QuerySummary;
  [ControllerType.BrandFacet]: Facet;
  [ControllerType.PriceFacet]: NumericFacet;
}

export const ControllerContext = createContext<ControllerContextType | null>(
  null,
);

export const useControllerContext = <T extends ControllerType>(
  controller: T,
): ControllerContextType[T] => {
  const context = useContext(ControllerContext);

  if (!context) {
    throw new Error(
      `useControllerContext must be used within a ControllerProvider`,
    );
  }

  return context[controller];
};

const partFields = [
  "part_number",
  "local_price",
  "shipment_delays",
  "part_status",
  "entity_id",
  "featured_part_order",
  "new_part_order",
  "admin_only",
  "hide_price"
];

interface EngineAndControllerProviderProps {
  accessToken: string;
  organizationId: string;
  shipmentSiteKey: number;
  children: any;
}

export const EngineAndControllerProvider: React.FC<
  EngineAndControllerProviderProps
> = (props) => {
  const region = useContext(RegionContext);
  const continentCode = useContext(ContinentContext);

  const standAloneSearchBoxData = localStorage.getItem("coveo_standalone_search_box_data");

  const analytics = standAloneSearchBoxData ? { analytics: { originLevel3: document.referrer } } : {};

  const searchEngine = createSearchEngine(
    {
      configuration: {
        organizationId: props.organizationId,
        accessToken: props.accessToken,
        search: { pipeline: "Parts"},
        ...analytics
      },
      searchHub: "PartLibrarySearch",
      preprocessRequestBody: (body: any) => ({
        ...body,
        fieldsToInclude: [...ecommerceFields, ...partFields],
        dictionaryFieldContext: {
          local_price: region,
          shipment_delays: props.shipmentSiteKey.toString(),
        },
        context: {
          continent: continentCode
        },
      }),
    }
  );

  const featuredPartsEngine = createSearchEngine(
    {
      configuration: {
        organizationId: props.organizationId,
        accessToken: props.accessToken,
        search: { pipeline: "Parts"}
      },
      searchHub: "PartLibrarySearch",
      preprocessRequestBody: (body: any) => ({
        ...body,
        fieldsToInclude: [...ecommerceFields, ...partFields],
        dictionaryFieldContext: {
          local_price: region,
          shipment_delays: props.shipmentSiteKey.toString(),
        },
        context: {
          continent: continentCode
        },
        numberOfResults: 7,
        sortCriteria: "@featured_part_order ascending",
        q: "@featured_part_order >= 0",
      }),
    }
  );

  const newPartsEngine = createSearchEngine(
    {
      configuration: {
        organizationId: props.organizationId,
        accessToken: props.accessToken,
        search: { pipeline: "Parts"}
      },
      searchHub: "PartLibrarySearch",
      preprocessRequestBody: (body: any) => ({
        ...body,
        fieldsToInclude: [...ecommerceFields, ...partFields],
        dictionaryFieldContext: {
          local_price: region,
          shipment_delays: props.shipmentSiteKey.toString(),
        },
        context: {
          continent: continentCode
        },
        numberOfResults: 7,
        sortCriteria: "@new_part_order ascending",
        q: "@new_part_order >= 0",
      }),
    }
  );

  const searchBoxController = buildSearchBox(searchEngine);

  const criteria: [string, SortCriterion][] = [
    ["Relevance", buildRelevanceSortCriterion()],
    [
      "Price (Ascending)",
      buildFieldSortCriterion("local_price", SortOrder.Ascending),
    ],
    [
      "Price (Descending)",
      buildFieldSortCriterion("local_price", SortOrder.Descending),
    ],
  ];
  const initialCriterion = criteria[0][1];

  const sortController = buildSort(searchEngine, {
    initialState: { criterion: initialCriterion },
  });

  const brandFacetController = buildFacet(searchEngine, {
    options: { field: "ec_brand", numberOfValues: 999 },
  });

  const categoryFacetController = buildFacet(searchEngine, {
    options: { field: "ec_category", numberOfValues: 999 },
  });
  const priceFilterController = buildNumericFilter(searchEngine, {
    options: { field: "local_price" },
  });
  const numericFacetController = buildNumericFacet(searchEngine, {
    options: { field: "local_price", generateAutomaticRanges: true },
  });

  buildResultsPerPage(searchEngine, {initialState: {numberOfResults: 20}});
  const pagerController = buildPager(searchEngine);
  const resultListController = buildResultList(searchEngine);
  const querySummaryController = buildQuerySummary(searchEngine);

  const featuredPartsResultListController =
    buildResultList(featuredPartsEngine);

  const newPartsResultListController = buildResultList(newPartsEngine);

  const { updateQuery } = loadQueryActions(searchEngine);

  useEffect(() => {
    const synchronizeWithUrl = initializeUrlManager(searchEngine);

    if (standAloneSearchBoxData) {
      localStorage.removeItem("coveo_standalone_search_box_data");
      const {value, analytics} = JSON.parse(standAloneSearchBoxData);
      searchEngine.dispatch(updateQuery({q: value}));
      searchEngine.executeFirstSearchAfterStandaloneSearchBoxRedirect(analytics);
    } else {
      synchronizeWithUrl();
      searchEngine.executeFirstSearch();
    }

  }, [searchEngine]);

  useEffect(
    () => featuredPartsEngine.executeFirstSearch(),
    [featuredPartsEngine],
  );

  useEffect(() => newPartsEngine.executeFirstSearch(), [newPartsEngine]);

  const controllers = {
    searchBoxController,
    sortController,
    criteria,
    categoryFacetController,
    priceFilterController,
    pagerController,
    resultListController,
    querySummaryController,
    featuredPartsResultListController,
    newPartsResultListController,
    brandFacetController,
    priceFacetController: numericFacetController,
  };

  const engines = {
    searchEngine,
    featuredPartsEngine,
    newPartsEngine,
  };

  return (
    <EngineContext.Provider value={engines}>
      <ControllerContext.Provider value={controllers}>
        {props.children}
      </ControllerContext.Provider>
    </EngineContext.Provider>
  );
};