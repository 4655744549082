import { useMutation } from "@tanstack/react-query";

export const useMutationGQL = <
  TResult,
  TParams extends any[],
>(
  keyName: string,
  request: (...args: TParams) => Promise<TResult>,
) => {
  const mutation = useMutation<TResult, Error, TParams>(
    {
      mutationKey: [keyName],
      mutationFn: async (params: TParams): Promise<TResult> => {
        return request(...params);
      },
    }
  );

  return {
    mutationFn: mutation.mutateAsync,
    status: mutation.status
    };
};
