import React from "react";
import I18n from "../../../../../../helpers/I18n";
import LandingPageMultiTile from "../../../../../../components/cms/landing_pages/sections/LandingPageMultiTile";

const Features = () => {
  return (
    <LandingPageMultiTile
      type="multiTile"
      imageMaxWidth={150}
      titleTextAlign="center"
      countTiles={4}
      textAlign="center"
      supertitle={I18n.t(
        "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.supertitle",
      )}
      title={I18n.t(
        "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.heading",
      )}
      subtitle={I18n.t(
        "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.subtitle",
      )}
      ctaBtn={{
        url: "",
        label: I18n.t(
          "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.ctaText",
        ),
      }}
      tiles={[
        {
          imageUrl:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/162/original/vention-icon-led-light.png?1599422489",
          title: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile1.title",
          ),
          description: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile1.description",
          ),
        },
        {
          imageUrl:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/619/original/certification-europe-us-resied-optimized.png?1644511381",
          title: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile2.title",
          ),
          description: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile2.description",
          ),
        },
        {
          imageUrl:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/169/original/vention-icon-control-module.png?1599422605",
          title: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile3.title",
          ),
          description: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile3.description",
          ),
        },
        {
          imageUrl:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/164/original/vention-icon-urcap.png?1599422523",
          title: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile4.title",
          ),
          description: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile4.description",
          ),
        },
        {
          imageUrl:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/163/original/vention-icon-actuators.png?1599422508",
          title: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile5.title",
          ),
          description: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile5.description",
          ),
        },
        {
          imageUrl:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/165/original/vention-icon-software-stop.png?1599422540",
          title: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile6.title",
          ),
          description: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile6.description",
          ),
        },
        {
          imageUrl:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/170/original/vention-icon-software.png?1599422622",
          title: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile7.title",
          ),
          description: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile7.description",
          ),
        },
        {
          imageUrl:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/167/original/vention-icon-icloud.png?1599422574",
          title: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile8.title",
          ),
          description: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile8.description",
          ),
        }
      ]}
    />
  );
};

export default Features;
