import React from "react";
import { PartCategoryByCode, RegionType } from "../Types";
import { Header } from "./Header";
import SearchBox from "../../../../coveo/components/part_library/SearchBox";
import { EngineAndControllerProvider } from "../../../../coveo/components/part_library/EngineAndControllerProvider";
import { RegionProvider } from "../../../../coveo/components/part_library/RegionProvider";
import { MainSection } from "../../../../coveo/components/part_library/MainSection";
import { PageLayout } from "../../../../components/erb_wrappers/layout/PageLayout";
import { ContinentProvider } from "../../../../coveo/components/part_library/ContinentProvider";
import { ContinentCode } from "../../../../constants/Geography";

interface PageProps {
  accessToken: string;
  organizationId: string;
  region: RegionType;
  shipmentSiteKey: number;
  partCategoryByCode: PartCategoryByCode;
  continentCode: ContinentCode | null;
}

const Page: React.FC<PageProps> = ({
  accessToken,
  organizationId,
  region,
  shipmentSiteKey,
  partCategoryByCode,
  continentCode
}) => (
  <PageLayout>
    <ContinentProvider continentCode={continentCode}>
      <RegionProvider region={region}>
        <EngineAndControllerProvider
          accessToken={accessToken}
          organizationId={organizationId}
          shipmentSiteKey={shipmentSiteKey}
        >
          <Header partCategoryByCode={partCategoryByCode}>
            <SearchBox />
          </Header>
          <MainSection partCategoryByCode={partCategoryByCode} />
        </EngineAndControllerProvider>
      </RegionProvider>
    </ContinentProvider>
  </PageLayout>
);

export default Page;
