import { ID } from "../../../../../api/Types";
import { PROJECT_LIST_ROUTE } from "../constants/routes";
export type Tabs = "details" | "resources" | "designs" | "parts" | "billing";

/**
 * methods that start with navigateTo uses location.href to navigate to a different page
 * methods that start with open uses window.open to open a new tab
 * @returns
 */

export const useProjectNavigation = () => {
  const navigateToPreviewFile = (projectId: ID, documentId: ID) => {
    window.location.href = `${PROJECT_LIST_ROUTE}/${projectId}/documents/${documentId}`;
  };

  const openToPreviewFile = (projectId: ID, documentId: ID) => {
    window.open(
      `${PROJECT_LIST_ROUTE}/${projectId}/documents/${documentId}`,
      "_blank",
    );
  };

  const getLinkToPreviewFile = (projectId: ID, documentId: ID) => {
    return `${PROJECT_LIST_ROUTE}/${projectId}/documents/${documentId}`;
  };

  const getLinkToSpecification = (projectId: ID, applicationCategoryId: ID) => {
    return `/dashboard/projects/${projectId}/specifications/${applicationCategoryId}/edit`;
  };

  const getLinkToEditProject = (
    projectId: ID,
    tab: Tabs | null,
    openShareModal = false,
  ) => {
    if (tab !== null) {
      return `${PROJECT_LIST_ROUTE}/${projectId}/edit?tab=${tab}&openShareModal=${openShareModal}`;
    } else {
      return `${PROJECT_LIST_ROUTE}/${projectId}/edit?openShareModal=${openShareModal}`;
    }
  };

  const navigateToProjectList = () => {
    window.location.href = PROJECT_LIST_ROUTE;
  };

  const nagivateToEditProject = (
    projectId: ID,
    tab: Tabs | null,
    openShareModal = false,
  ) => {
    window.location.href = getLinkToEditProject(projectId, tab, openShareModal);
  };

  const openToMachineBuilder = (designId: ID) =>
    window.open(`/machine-builder/${designId}`, "_blank");

  const redirectTo401ErrorPage = () => {
    window.location.href = "/403";
  };

  const openToCreateANewProject = () => {
    window.open(
      "/dashboard/projects?_action=create&title=New+project&description=Add+description",
      "_blank",
    );
  };

  return {
    navigateToProjectList,
    navigateToPreviewFile,
    getLinkToEditProject,
    nagivateToEditProject,
    openToPreviewFile,
    openToMachineBuilder,
    redirectTo401ErrorPage,
    openToCreateANewProject,
    getLinkToPreviewFile,
    getLinkToSpecification,
  };
};
