import {
  AtomicBreadbox,
  AtomicDidYouMean,
  AtomicFacetManager,
  AtomicLayoutSection,
  AtomicLoadMoreResults,
  AtomicNoResults,
  AtomicQueryError,
  AtomicQuerySummary,
  AtomicRefineToggle,
  AtomicSearchBox,
  AtomicSearchInterface,
  AtomicSearchLayout,
  AtomicSortDropdown,
  AtomicSortExpression,
  AtomicResultSectionVisual,
  AtomicResultImage,
  AtomicResultSectionTitle,
  AtomicResultLink,
  AtomicResultList,
  AtomicResultSectionExcerpt,
  AtomicCategoryFacet,
  AtomicSearchBoxQuerySuggestions,
  AtomicResultText,
  AtomicResultSectionBottomMetadata,
  AtomicResultPrintableUri,
} from "@coveo/atomic-react";
import React from "react";
import { Result } from "@coveo/headless";
import { createSearchEngine } from "../../../../coveo/utils/createEngine";

const renderResult = (result: Result): JSX.Element => {
  const descriptionField = result.excerpt
    ? "excerpt"
    : result.raw.description
      ? "description"
      : "ec_description";
  const imageField = result.raw.image ? "image" : "ec_images";

  return (
    <>
      {/*
        This element is rendered from a template, and so its shadow DOM isn't accessible by global CSS. Sometimes these
        templates that Coveo uses have `part` attributes that can be used to target the shadow DOM elements, but not in
        this case.
      */}

      <style>{`
      atomic-result-section-title,
      atomic-result-section-excerpt,
      atomic-result-section-bottom-metadata {
        padding: 0 20px;
      }

      atomic-result-section-excerpt,
      atomic-result-section-bottom-metadata {
        margin-top: 8px !important;
      }

      atomic-result-section-excerpt {
        max-height: 3em !important;
      }

      atomic-result-link a {
        text-decoration: none !important;
      }

      atomic-result-printable-uri a {
        color: var(--atomic-primary) !important;
      }

      atomic-result-text[field="source"],
      atomic-result-text[field="part_number"]
      {
        display: block;
        color: var(--atomic-light-text);
        font-size: 0.8rem;
      }

      atomic-result-text[field="ec_description"] {
        color: var(--atomic-lighter-text);
      }
    `}</style>
      <AtomicResultSectionVisual>
        <AtomicResultLink>
          <AtomicResultImage
            field={imageField}
            fallback="https://assets.vention.io/images/global-search/default-image-1x1.png"
          />
        </AtomicResultLink>
      </AtomicResultSectionVisual>
      <AtomicResultSectionTitle>
        <AtomicResultText field="source"/>
        <AtomicResultLink />
        <AtomicResultText field="part_number"/>
      </AtomicResultSectionTitle>
      <AtomicResultSectionExcerpt>
        <AtomicResultLink>
          <AtomicResultText field={descriptionField} />
        </AtomicResultLink>
      </AtomicResultSectionExcerpt>
      <AtomicResultSectionBottomMetadata>
        <AtomicResultPrintableUri />
      </AtomicResultSectionBottomMetadata>
    </>
  );
};

interface PageProps {
  accessToken: string;
  organizationId: string;
  locale: "en" | "fr";
}

const Page: React.FC<PageProps> = ({ accessToken, organizationId, locale }) => {
  const searchEngine = createSearchEngine(
    {
      configuration: {
        organizationId,
        accessToken,
        search: { pipeline: "Global Search" },
      },
      searchHub: "GlobalSearch",
      preprocessRequestBody: (body) => {
        body.fieldsToInclude.push("description");
        body.fieldsToInclude.push("part_number");
        body.fieldsToInclude.push("image");

        return body;
      }
    }
  );

  return (
    <AtomicSearchInterface
      languageAssetsPath="https://assets.vention.io/coveo-atomic-assets/lang"
      iconAssetsPath="https://assets.vention.io/coveo-atomic-assets/assets"
      engine={searchEngine}
      language={locale}
    >
      <AtomicSearchLayout id="global-search-page" className="search-page">
        <AtomicLayoutSection section="search">
          <div className="search-page__header">
            <div className="search-page__section-content">
              <h2 className="search-page__title">Global Search</h2>
              <AtomicSearchBox>
                <AtomicSearchBoxQuerySuggestions />
                <AtomicDidYouMean />
              </AtomicSearchBox>
            </div>
          </div>
        </AtomicLayoutSection>
        <div className="search-page__search-results search-page__section-content">
          <div className="search-page__facets">
            <AtomicLayoutSection section="facets">
              <AtomicFacetManager>
                <AtomicCategoryFacet field="source" label="Refine by" />
              </AtomicFacetManager>
            </AtomicLayoutSection>
          </div>
          <div className="search-page__main">
            <AtomicLayoutSection section="main">
              <AtomicLayoutSection section="status">
                <AtomicBreadbox />
                <AtomicQuerySummary />
                <AtomicRefineToggle />
                <AtomicSortDropdown>
                  <AtomicSortExpression
                    label="relevance"
                    expression="relevancy"
                  />
                  <AtomicSortExpression
                    label="Newest"
                    expression="date descending"
                  />
                </AtomicSortDropdown>
              </AtomicLayoutSection>
              <AtomicLayoutSection section="results">
                <AtomicResultList
                  display="list"
                  imageSize="small"
                  template={(result) => ({
                    contentTemplate: renderResult(result),
                    linkTemplate: <></>,
                  })}
                />
                <AtomicQueryError />
                <AtomicNoResults />
              </AtomicLayoutSection>
              <AtomicLayoutSection section="pagination">
                <AtomicLoadMoreResults />
              </AtomicLayoutSection>
            </AtomicLayoutSection>
          </div>
        </div>
      </AtomicSearchLayout>
    </AtomicSearchInterface>
  );
};

export default Page;
